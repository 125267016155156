// VARIABLES

$theme-colors: (
  'background': #FAFAFA,
  'white': #FFFFFF,
  'black': #333333,
  'grey': #737373,
  'red': #F47458,
  'orange': #FF9700,
  'yellow': #F7CC7F,
  'darkYellow': #EBB636,
  'green': #21EAB0,
  'blue': #86B1F2,
  'darkBlue': #184655,
  'greenWater': #77C6C3
);

$Quicksand: 'Quicksand', sans-serif;