@import "./Variables.scss";

// GENERAL

body {
  margin: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  font-family: $Quicksand;
  background-color: map-get($theme-colors, "background");
  background-size: cover;
  background-position: center 300%;
}

// CENTRATURA ELEMENTI

.u-center-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.u-center-vertical {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.u-center-horizontal {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.u-center-horizontal-inner {
  display: table;
  margin: 0 auto;
}

.d-block {
  display: block;
}

// BUTTONS

button {
  transition: 0.2s ease-in-out;
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

// ANIMATION

@keyframes swing {
  0% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}

.swing {
  animation: swing ease-in-out 0.8s infinite alternate;
}

@keyframes shake {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
    transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

.shake {
  animation: shake ease-in-out 1.5s infinite alternate;
}

.animation-d-2{
  animation-delay: 0.3s;
}

.animation-d-3{
  animation-delay: 0.5s;
}

@keyframes pulsate-fwd {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.pulsate {
  animation: pulsate-fwd 1s ease-in-out infinite both;
}

.carousel-inner .carousel-item {
  transition: -webkit-transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spin {
  width: 60px;
  height: auto;
  -webkit-animation: spin 0.8s linear infinite;
  -moz-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}

@keyframes ani-bee {
	0%{ left: -20px;transform: rotate(30deg);}
	50% {left: 100%;transform: rotate(-360deg);}
	100% {left: -20px;transform: rotate(30deg);}
}

.bee{
  width: 60px;
	margin-top: 40px;
	position:relative;
	animation: ani-bee 9s ease-in-out infinite;
  transform-origin: 0% 0%;
  @media only screen and (max-height: 749px){
    display: none;
  }
}

// MARGIN & PADDING

.m-b-0 {
  margin-bottom: 0;
}

.m-tb-10{
  margin-top: 10px;
  margin-bottom: 20px;
}

.no-padding {
  padding: 0 !important;
}

.ape-position {
  width: 60px;
  height: auto;
  position: absolute;
  top: -30px;
  right: 20px;
}

.line-position{
  position: absolute;
  z-index: -100;
  height: 210px;
  top: 0px;
  right: 5px;
  width: auto;
}

.p-lr-16{
  padding-left: 16px;
  padding-right: 16px;
}

// PROGRESS BAR

.course-progress {
  .progress {
    position: relative;
    height: 100px;
    border-radius: 20px;
    width: 400px;
    margin-bottom: 25px;
    @media only screen and (max-width: 768px) {
      width: 65%;
    }
    &.green-water {
      background-color: rgba(map-get($theme-colors, "greenWater"), $alpha: 0.6);
      border: 2px solid #6DB5B3;
      .progress-bar {
        background-color: map-get($theme-colors, "greenWater");
      }
    }
    &.yellow {
      background-color: rgba(map-get($theme-colors, "yellow"), $alpha: 0.6);
      border: 2px solid map-get($theme-colors, "darkYellow");
      .progress-bar {
        background-color: map-get($theme-colors, "yellow");
      }
    }
    &.blue {
      background-color: rgba(map-get($theme-colors, "blue"), $alpha: 0.6);
      border: 2px solid #739EE0;
      .progress-bar {
        background-color: map-get($theme-colors, "blue");
      }
    }
    p {
      color: map-get($theme-colors, "darkBlue");
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 0;
      padding: 0 16px;
    }
    &::before {
      content: '';
      width: 30px; 
      height: 30px;
      border-radius: 100%;
      border: 6px solid rgba(#E5E5E5, 0.4);
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      animation: ring 1.5s infinite;
    }
  }
}

@keyframes ring {
  0% {
    width: 30px;
    height: 30px;
    opacity: 1;
  }
  100% {
    width: 300px;
    height: 300px;
    opacity: 0;
  }
}

// MODAL

.modal-dialog {
  max-width: inherit;
  width: 100%;
  margin: 0 auto;
  .modal-content {
    height: 100vh;
    width: 100%;
    border-radius: 0px;
    border: none;
    .cont-spinner {
      text-align: center;
      width: 100%;
      padding: 16px;
      h1 {
        margin-bottom: 20px;
      }
    }
  }
}

// COURSE DETAIL

ul {
  &.no-list {
    list-style: none;
    padding: 0;
    max-width: 600px;
    margin: auto;
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

@keyframes wobble {
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  75% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(0deg);
  }
}


.height-div{
  height: 19px;
}

.custom-control-input:checked~.custom-control-label::before{
  background-color: map-get($theme-colors, "blue");
  border-color: map-get($theme-colors, "blue");
}

.custom-control-input:focus~.custom-control-label::before{
  box-shadow: none;
}

form{
  .form-group{
    .custom-control-label{
      a{
        color: map-get($theme-colors, "blue");
        font-weight: 500;
      }
    }
  }
}

.checkboxRow{
  @media only screen and (max-width: 420px) {
    :nth-child(even) {
      margin-left: 19px;
    }
    .col-5{
      padding: 0;
    }

  }
}